import * as React from 'react'
import {
	type Block as BlockType,
	type FormatPosition,
} from '../../types/graphql.page.ts'
import Block from './blocks/block.tsx'

function getColumnSize(block: BlockType) {
	let size = 1
	if (
		block.__typename === 'contentBlocks_text_BlockType' ||
		block.__typename === 'contentBlocks_image_BlockType'
	) {
		const fraction = block.format[0]?.columnSize.split('-')[1]
		size =
			fraction === 'full'
				? 1
				: Number(fraction?.split('/')[0]) / Number(fraction?.split('/')[1])
	}
	return size
}

export function getBlockLocations(blocks: BlockType[] | undefined) {
	if (!blocks) return []
	let locations: FormatPosition[] = []
	let previousSize: number
	let previousPosition: FormatPosition = 'left'
	let currentRowTotal = 0

	blocks.forEach((block, idx) => {
		const size = getColumnSize(block)
		currentRowTotal += size
		if (idx === 0) {
			locations.push('left')
		} else {
			if (previousSize + size > 1) {
				locations.push('left')
				if (previousPosition === 'center') {
					locations[locations.length - 2] = 'right'
				}
				currentRowTotal = 0
			} else if (currentRowTotal === 0) {
				locations.push('left')
			} else {
				locations.push('center')
			}
		}
		previousSize = size
		previousPosition = locations[locations.length - 1] || 'left'
	})
	if (locations[locations.length - 1] === 'center') {
		locations[locations.length - 1] = 'right'
	}
	return locations
}

function getHasExtraSpacings(
	blocks: BlockType[] | undefined,
	blockLocations: FormatPosition[],
) {
	let extraSpacings: boolean[] = []
	if (!blocks) return []
	if (!blocks.length) return extraSpacings
	let previousRowHasTextOrImage = false
	blockLocations.forEach((location, idx) => {
		if (idx === 0) extraSpacings.push(false)
		if (location === 'left') {
			if (
				blocks[idx - 1]?.__typename === 'contentBlocks_text_BlockType' ||
				blocks[idx - 1]?.__typename === 'contentBlocks_image_BlockType'
			) {
				previousRowHasTextOrImage = true
				extraSpacings.push(true)
			} else {
				extraSpacings.push(false)
			}
		} else if (location === 'right') {
			if (previousRowHasTextOrImage) {
				extraSpacings.push(true)
			} else {
				extraSpacings.push(false)
			}
			previousRowHasTextOrImage = false
		} else {
			if (previousRowHasTextOrImage) {
				extraSpacings.push(true)
			} else {
				extraSpacings.push(false)
			}
		}
	})
	return extraSpacings
}

export default function Blocks({
	blocks,
	className,
}: {
	blocks: Array<BlockType>
	className?: string
}) {
	const locations = getBlockLocations(blocks)
	const hasExtraSpacings = getHasExtraSpacings(blocks, locations)

	return (
		<main
			className={`container flex flex-col place-items-center gap-10 bg-white py-20 lg:grid lg:grid-cols-12 lg:gap-y-20 ${className}`}
		>
			{blocks.map((block, index) => (
				<Block
					key={index}
					hasExtraSpacing={hasExtraSpacings[index]}
					block={block}
					location={locations[index] || 'left'}
				/>
			))}
		</main>
	)
}
