import { getBlockColSize } from '#app/utils/misc'
import {
	type FormatPosition,
	type ImageBlock as ImageBlockType,
} from '../../../types/graphql.page.ts'
import Image from '../image.tsx'

export default function ImageBlock({
	block,
	location,
	className = '',
}: {
	className?: string
	block: ImageBlockType
	location: FormatPosition
}) {
	const colSpan = getBlockColSize(block)
	const image = block.image[0]?.url && block.image[0]
	const hasWaveTexture =
		Array.isArray(block.format) &&
		block.format[0]?.styles &&
		block?.format[0]?.styles.includes('wave-texture')
	const waveTextureClass = hasWaveTexture ? `wave-${location}` : ''

	const imageAlignment =
		block.image[0]?.width && block.image[0]?.width > block.image[0]?.height
			? 'horizontal'
			: 'vertical'
	const imageRatio =
		(block.image[0]?.width || 1) / (block.image[0]?.height || 1)
	const height =
		imageAlignment === 'vertical' ? 'h-auto' : `h-[${688 * imageRatio}px]`
	const width =
		imageAlignment === 'horizontal'
			? 'lg:w-[688px]'
			: `lg:w-[${688 / imageRatio}px]`

	return image ? (
		<article
			data-block="image"
			className={`${className} relative w-full ${colSpan}`}
		>
			<div className={`z-20 w-full ${waveTextureClass}`}>
				<Image
					img={image}
					width={block.image[0]?.width}
					height={block.image[0]?.height}
					className={`relative z-20 w-full ${height} ${width}`}
				/>
			</div>
		</article>
	) : null
}
