import { Link } from '@remix-run/react'
import {
	type LeadershipBlock as LeadershipBlockType,
	type LeadershipListEntry,
} from '../../../types/graphql.page.ts'
import BackgroundImage from '../backgroundImage.tsx'

export default function LeadershipBlock({
	block,
	leaders,
}: {
	block: LeadershipBlockType
	leaders: LeadershipListEntry[]
}) {
	return (
		<article
			data-block="leadership"
			className="relative w-screen bg-green-300/5 py-20 md:col-span-12"
		>
			<div className="container flex flex-col space-y-20">
				<header className="flex flex-col space-y-4">
					<h2 className="top-header">{block.subheader}</h2>
					<h2 className="font-sans-serif text-copper-100 text-5xl font-light leading-[58px] tracking-wide">
						{block.header}
					</h2>
				</header>
				<div className="leaders grid grid-cols-1 gap-10 gap-y-20 sm:grid-cols-2">
					{leaders.map((leader) => (
						<Leader key={leader.uid} leader={leader} />
					))}
				</div>
			</div>
		</article>
	)
}

function Leader({ leader }: { leader: LeadershipListEntry }) {
	return (
		<Link to={`/${leader.uri}`}>
			<div className="leader group flex w-full flex-col space-y-5">
				<div className="relative aspect-[4/3] h-full w-full transform overflow-hidden transition-all duration-300 ease-in-out group-hover:scale-95">
					{leader.image[0] ? (
						<BackgroundImage
							img={leader.image[0]}
							className="absolute h-full w-full transform bg-contain bg-center bg-no-repeat transition-all duration-300 ease-in-out group-hover:scale-125"
						/>
					) : (
						<BackgroundImage
							img={{
								alt: leader.name,
								height: 40,
								width: 40,
								title: leader.name,
								uid: '',
								url: 'data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%27768%27 height=%27768%27 style=%27background:%2300362e%27 /%3E',
							}}
							className=""
						/>
					)}
				</div>
				<div className="flex flex-col space-y-1">
					<h3 className="font-serif text-3xl leading-[44.8px] text-green-300">
						{leader.name}
					</h3>
					<h6 className="text-lg leading-[28.8px] tracking-wide text-gray-100">
						{leader?.title}
					</h6>
				</div>
				<hr className="bg-copper-100 h-0.5 w-[70px] border-0" />
			</div>
		</Link>
	)
}
