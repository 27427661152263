import { getBlockColSize } from '#app/utils/misc'
import {
	type FormatPosition,
	type TextBlock as TextBlockType,
} from '../../../types/graphql.page.ts'
import ArrowLink from '../arrowLink.tsx'

export default function TextBlock({
	block,
	location,
	className = '',
}: {
	className?: string
	block: TextBlockType
	location: FormatPosition
}) {
	const colSpan = getBlockColSize(block)
	const hasLink = Boolean(block?.link?.url)
	const hasSubheader = Boolean(block?.subheader)
	const hasListing = Array.isArray(block.listing) && block.listing.length > 0
	const listing = block.listing && block.listing[0]

	return (
		<article data-block="text" className={`${className} relative ${colSpan}`}>
			<div className="flex flex-col space-y-4 bg-[inherit]">
				{hasSubheader && <h2 className="header">{block.subheader}</h2>}
				<div
					className="flex flex-col space-y-5"
					dangerouslySetInnerHTML={{
						__html: block.text,
					}}
				></div>
				{hasListing && (
					<div className="flex flex-col space-y-6 pt-5">
						<h4 className="text-base font-semibold text-gray-100">
							{listing?.title}
						</h4>
						<div className="grid grid-cols-1 gap-10 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
							{listing?.item.map((item) => (
								<div
									key={item.uid}
									className="col-span-1 flex w-fit flex-row place-items-center space-x-5"
								>
									<img
										className="w-6"
										src={`https://craft.settooncapital.com${item.icon[0]?.url}`}
										alt={item.icon[0]?.alt || 'listing'}
										width={item.icon[0]?.width}
										height={item.icon[0]?.height}
									/>
									<div className="font-normal">{item.text}</div>
								</div>
							))}
						</div>
					</div>
				)}
				{hasLink && (
					<ArrowLink
						className="pt-[15px]"
						url={block.link.url}
						type={block.link.type}
						text={block.link.text}
					/>
				)}
			</div>
		</article>
	)
}
