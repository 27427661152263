import { type BackgroundBlock as BackgroundBlockType } from '../../../types/graphql.page.ts'
import { getBlockLocations } from '../blocks.tsx'
import Block from './block.tsx'

export default function BackgroundBlock({
	block,
}: {
	block: BackgroundBlockType
}) {
	const locations = getBlockLocations(block.blocks)

	return (
		<article
			data-block="background"
			className="background relative z-30 w-screen bg-white md:col-span-12"
		>
			<div className="w-full bg-green-300/5 pb-24 pt-20">
				<div className="container flex flex-col gap-10 md:grid md:grid-cols-12">
					{block.blocks?.map((block, index) => (
						<Block
							block={block}
							location={locations[index] || 'left'}
							key={block.uid}
						/>
					))}
				</div>
			</div>
		</article>
	)
}
