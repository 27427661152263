import * as React from 'react'
import { useWindowSize } from '#app/hooks/useWindowSize.ts'
import { cn } from '#app/utils/misc.tsx'
import {
	type HistoryBlock as HistoryBlockType,
	type HistoryListEntry,
} from '../../../types/graphql.page.ts'
import Image from '../image.tsx'
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectGroup,
	SelectItem,
} from '../ui/select.tsx'

function useScrollPosition() {
	const [scrollPosition, setScrollPosition] = React.useState(0)
	const historyRef = React.useRef<HTMLDivElement>(null)

	React.useEffect(() => {
		const currentRef = historyRef.current
		const updatePosition = () => {
			setScrollPosition(historyRef.current?.scrollLeft || 0)
		}
		currentRef?.addEventListener('scroll', updatePosition)
		updatePosition()
		return () => currentRef?.removeEventListener('scroll', updatePosition)
	}, [])

	return { historyRef, scrollPosition }
}

export default function HistoryBlock({
	block,
	years,
}: {
	block: HistoryBlockType
	years: HistoryListEntry[]
}) {
	const { width } = useWindowSize()
	const [currentYear, setCurrentYear] = React.useState(0)
	const { historyRef, scrollPosition } = useScrollPosition()
	// const historyRef = React.useRef<HTMLDivElement>(null)
	const yearList = years.map((y) => y.historyYear)
	const isSmall = width <= 640
	// const isMedium = width <= 1280

	function scrollTo(year: string) {
		if (!historyRef.current) return
		const idx = yearList.indexOf(year)
		// setCurrentYear(idx)

		// const isSmall = width <= 500;
		historyRef.current.scrollTo({
			behavior: 'smooth',
			left: ((isSmall ? width : 439) + 40) * idx,
			top: 0,
		})
	}

	React.useEffect(() => {
		// update the current year when the scroll position changes
		// const isSmall = width <= 1024;
		const idx = Math.round(scrollPosition / ((isSmall ? width : 439) + 40))

		setCurrentYear(idx)
	}, [isSmall, scrollPosition, width])

	return (
		<article
			data-block="history"
			className="relative z-30 w-screen bg-green-200 pb-20 pt-10 md:col-span-12 md:py-20"
		>
			<div className="container flex flex-col pt-4 md:pt-24">
				<header className="flex flex-col space-y-4 pb-5">
					<h2 className="top-header-white">{block.subheader}</h2>
					<h2 className="font-sans-serif text-copper-100 text-5xl font-light leading-[58px] tracking-wide">
						{block.header}
					</h2>
				</header>
				<div className="flex min-h-[350px] flex-col gap-12">
					<div className="hidden gap-x-14 gap-y-7 md:flex md:flex-row md:flex-wrap">
						{yearList.map((year, idx) => (
							<button
								type="button"
								key={year}
								onClick={() => scrollTo(year)}
								className={`font-sans-serif pb-[3px] text-xl font-extralight leading-10 text-white ${
									idx === currentYear
										? 'border-b-copper-100 border-b-2 pb-[1px]'
										: 'pb-[3px]'
								}`}
							>
								{year}
							</button>
						))}
					</div>
					<div className="md:hidden">
						<Select
							value={yearList[currentYear]}
							onValueChange={(value) => {
								scrollTo(value)
							}}
						>
							<SelectTrigger className="border-b-copper-100 font-sans-serif w-[125px] border-0 border-b-2 text-xl font-extralight leading-10 text-white">
								<SelectValue placeholder={yearList[currentYear]} />
							</SelectTrigger>
							<SelectContent className="bg-copper-400">
								<SelectGroup>
									{/* <SelectLabel>{yearList[currentYear]}</SelectLabel> */}
									{yearList.map((year) => (
										<SelectItem value={year} key={year}>
											{year}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>
					<div
						ref={historyRef}
						className="no-scrollbar top-80 ml-[-30px] w-[100000px] overflow-y-hidden overflow-x-scroll lg:ml-0"
					>
						<div
							className={cn([
								'no-scrollbar flex flex-row gap-10',
								// `w-[calc(100%+479px*13)]`,
							])}
							style={{
								width: `calc(100% + ${isSmall ? width : 479}px * ${
									years.length
								} + 60px)`,
							}}
						>
							{years.map((year) => (
								<Year entry={year} key={year.uid} />
							))}
						</div>
					</div>
				</div>
			</div>
		</article>
	)
}

function Year({ entry }: { entry: HistoryListEntry }) {
	return (
		<div
			data-year={entry.historyYear}
			className="block max-h-[322px] min-h-[322px] min-w-[100vw] max-w-[100vw] bg-white p-7 sm:min-w-[439px] sm:max-w-[439px]"
		>
			<div className="leader group flex h-full w-full flex-col justify-between space-y-5">
				<div className="flex flex-col space-y-1">
					<h3 className="font-serif text-3xl leading-[44.8px] text-green-300">
						{entry.historyYear}
					</h3>
					<h6 className="text-lg leading-[28.8px] tracking-wide text-gray-100">
						{entry.historyDetails}
					</h6>
				</div>
				<div className="flex max-h-32 flex-col place-items-center justify-items-stretch">
					{entry.historyLogo[0]?.url ? (
						<Image img={entry.historyLogo[0]} className="h-32" />
					) : null}
				</div>
			</div>
		</div>
	)
}
