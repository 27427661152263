import { useLoaderData } from '@remix-run/react'
import {
	type HistoryListEntry,
	type Block as BlockType,
	type FormatPosition,
	type LeadershipListEntry,
} from '../../../types/graphql.page.ts'
import BackgroundBlock from './backgroundBlock.tsx'
import HistoryBlock from './historyBlock.tsx'
import ImageBlock from './imageBlock.tsx'
import LeadershipBlock from './leadershipBlock.tsx'
import TextBlock from './textBlock.tsx'
import TextImageOverlayBlock from './textImageOverlayBlock.tsx'

export default function Block({
	block,
	location,
	hasExtraSpacing,
}: {
	hasExtraSpacing?: boolean
	block: BlockType
	location: FormatPosition
}) {
	const { leadershipList, historyList } = useLoaderData<{
		leadershipList: Array<LeadershipListEntry>
		historyList: Array<HistoryListEntry>
	}>()

	return block.__typename === 'contentBlocks_text_BlockType' ? (
		<TextBlock
			key={block.uid}
			block={block}
			location={location}
			className={`${hasExtraSpacing ? 'lg:pt-16' : ''}`}
		/>
	) : block.__typename === 'contentBlocks_textImageOverlay_BlockType' ? (
		<TextImageOverlayBlock key={block.uid} block={block} />
	) : block.__typename === 'contentBlocks_image_BlockType' ? (
		<ImageBlock
			key={block.uid}
			block={block}
			location={location}
			className={`${hasExtraSpacing ? 'lg:pt-16' : ''}`}
		/>
	) : block.__typename === 'contentBlocks_history_BlockType' ? (
		historyList ? (
			<HistoryBlock
				key={block.uid}
				years={historyList as HistoryListEntry[]}
				block={block}
			/>
		) : null
	) : block.__typename === 'contentBlocks_leadership_BlockType' ? (
		leadershipList ? (
			<LeadershipBlock
				leaders={leadershipList as LeadershipListEntry[]}
				block={block}
				key={block.uid}
			/>
		) : null
	) : block.__typename === 'contentBlocks_background_BlockType' ? (
		<BackgroundBlock key={block.uid} block={block} />
	) : null
}
